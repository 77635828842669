import {Module} from "vuex";
import axios from "@/plugins/axios";
import Vue from "vue";
import moment from "moment";

export interface IPrinter {
    id: number
    company_id: number
    name: string
    description: string
    rtsp: string
    webhook_url: string
    status: string
    estimated_print_time: number
    cpu_temp: number
    extruder_temperature: number
    extruder_target: number
    extruder_power: number
    heater_bed_temperature: number
    heater_bed_power: number
    heater_bed_target: number
    heater_generic_temperature: number
    print_stats_filament_used: number
    print_stats_print_duration: number
    print_stats_total_duration: number
    print_stats_filename: string
    print_stats_state: string
    print_stats_message: string
    toolhead_print_time: number
    display_status_progress: number
    idle_timeout_printing_time: number
    idle_timeout_state: string
    last_status_changed_at: string
    webhooks_state: string
    webhooks_state_message: string
    updated_at: string
}

const printerModule = <Module<any, any>>{
    namespaced: true,
    state: () => ({
        printers: <IPrinter[]>[]
    }),
    mutations: {},
    getters: {
        printers: state => state.printers
    },
    actions: {
        async getPrinters({state}) {
            const body: any = await axios.get(`printers`)
            state.printers = body.printers;
        },
        updatePrinter({state}, {printer_id, values}) {
            const printerIdx = state.printers.findIndex((p: IPrinter) => p.id === printer_id);
            if (printerIdx >= 0) {
                if (Object.keys(values).includes('print_stats_state'))
                    Vue.set(state.printers, printerIdx, {
                        ...state.printers[printerIdx], ...values,
                        last_status_changed_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                        updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                    });
            }
        }
    }
}

export default printerModule;